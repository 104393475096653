



































import {Component, Prop, Vue} from 'vue-property-decorator';
import {getFileUrl} from '@/utilities/getUrl';

@Component
export default class Upload extends Vue {
  private getFileUrl = getFileUrl;

  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  private singleDocument!: boolean;

  @Prop({
    type: Array,
    required: false
  })
  private documents!: [];

  @Prop({
    type: String,
    required: false
  })
  private documentName!: string;

  @Prop({
    type: Object,
    required: false,
  })
  private document!: object;

  private documentIsImage(type: string): boolean {
    return type === 'JPG' || type === 'PNG';
  }
}
