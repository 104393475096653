












































































import { Component, Vue } from 'vue-property-decorator';
import ProgressBarRatio from '@/components/Ui/ProgressBarRatio.vue';
import PolicyProtectionBlock from '@/pages/Policy/Policy.protection.block.vue';
import {PolicyStatusEnum} from '@/interfaces';
import { getFullDate } from '@/utilities/time';
import {formatCurrency} from '@/utilities/currency';

@Component({
  components: {
    ProgressBarRatio,
    PolicyProtectionBlock
  }
})
export default class PolicySummaryTab extends Vue {
  public PolicyStatusEnum = PolicyStatusEnum;
  private getFullDate = getFullDate;

  private get getPolicyInfo() {
    return this.$store.getters.getPolicyItemInfo;
  }

  private get getFullAddress() {
    const address = this.getPolicyInfo.address;
    return address.street && address.streetNumber
      ? `${address.street} ${address.streetNumber} ${address.zip} ${address.city}, ${address.country}`
      : '-';
  }

  private get getPaymentFormula() {
    return this.getPolicyInfo.paymentPeriod
      ? this.$t('policy.summary.perPeriod', [
      formatCurrency(this.getPolicyInfo.premiumAmount, this.getPolicyInfo.currency),
      this.$t(`policy.summary.paymentFormula.${this.getPolicyInfo.paymentPeriod}`)
      ])
      : '-';
  }

}
