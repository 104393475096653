





























import { Component, Vue } from 'vue-property-decorator';
import PolicySummaryTab from '@/pages/Policy/Policy.summary.tab.vue';
import PolicyDocumentsTab from '@/pages/Policy/Policy.documents.tab.vue';
import PolicyHistoryTab from '@/pages/Policy/Policy.history.tab.vue';
import {i18n} from '@/main';
import {TranslateResult} from 'vue-i18n';

@Component({
  metaInfo() {
    const tab: TranslateResult = i18n.t(`meta.policy.items[${Number(this.$route.query.tab) || 0}]`);
    return {
      title: tab['title'],
      meta: [
        {
          name: 'description',
          content: tab['description']
        }
      ]
    };
  },
  components: {
    PolicySummaryTab,
    PolicyDocumentsTab,
    PolicyHistoryTab
  }
})
export default class PolicyPage extends Vue {
  private currentTab = 0;

  public created() {
    if (this.$route.query.tab) {
      this.currentTab = Number(this.$route.query.tab);
    }

    this.$store.dispatch('getPolicyInfo', this.$route.params.id);
    if (this.$route.query.tab && (this.$route.query.tab === 'documents')) {
      this.currentTab = 1;
    }
  }

  private changeTab() {
    this.$router.push({query: {...this.$route.query, tab: String(this.currentTab)}})
      .catch(() => null);
  }
}
