


























































import { Component, Vue } from 'vue-property-decorator';
import ArrowIcon from '@/assets/icons/arrow.svg';
import InfoIcon from '@/assets/icons/info.svg';
import Document from '@/components/Document.vue';
import Uploader from '@/components/Ui/Uploader/Uploader.vue';
import { UploadTypeEnum } from '@/interfaces';

@Component({
  components: {
    ArrowIcon,
    Document,
    Uploader,
    InfoIcon,
  }
})
export default class PolicyDocumentsTab extends Vue {
  public UploadTypeEnum = UploadTypeEnum;
  public commonImageFileTypes = [
    '.PDF',
    '.DOC',
    '.DOCX',
    '.XLS',
    '.XLSX',
    '.PNG',
    '.JPG',
    '.JPEG',
    '.MP3',
    '.MP4',
    '.MKW',
    '.MOV',
  ];
  private get getPolicyInfo() {
    return this.$store.getters.getPolicyItemInfo;
  }

  private get attachments() {
    return this.getPolicyInfo && this.getPolicyInfo.attachments
      ? this.getPolicyInfo.attachments
      : [];
  }

  private handleRentUpload(event, type) {
    const selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append('file', selectedFile, selectedFile.name);
    formData.append('type', type);
    formData.append('origin', 'POLICY');
    formData.append('id', this.$route.params.id);
    this.$store.dispatch('uploadPolicyFile', formData)
      .catch((error) => {
        if (error.response.data.detail
          && error.response.data.detail === 'The file is too large (50 MB max)'
        ) {
          this.$notify({
            group: 'notification',
            type: 'notification-error',
            text: this.$tc('uploader.uploadFileMaxSize'),
          });
        }
        if (error.response.data.messages
          && error.response.data.messages.file === 'File type is not allowed'
        ) {
          this.$notify({
            group: 'notification',
            type: 'notification-error',
            text: this.$tc('validation.server.INVALID_PHONE_NUMBER'),
          });
        }
      });
  }

  private uploadPolicyAttachments(file: File) {
    return this.$store.dispatch(
      'uploadPolicyAttachments',
      {file, id: this.$route.params.id}
    );
  }

  private removePolicyAttachments(documentId: string) {
    return this.$store.dispatch(
      'removePolicyAttachments',
      {documentId, id: this.$route.params.id}
    );
  }
}
