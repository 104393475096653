import router from '@/router';
import {IPaginationOptions, sortType} from '@/interfaces';
import {getSortOptions, ISortQuery} from '@/dictionaries/sort.dictionary';
import store from '@/store';

export const paginationOptions = (): IPaginationOptions => {
  return { page: 1, itemsPerPage: store.getters.isMobileAdaptiveMode ? 5 : 10 };
};

export const getPaginationOptionsFromQuery = (options: IPaginationOptions): IPaginationOptions => {
 return router.currentRoute.query?.page && router.currentRoute.query?.itemsPerPage
  ? {
      page: Number(router.currentRoute.query.page),
      itemsPerPage: Number(router.currentRoute.query?.itemsPerPage),
   }
  : options;
};

export const getSortFromQuery = (sortOptions: object = {}): ISortQuery | object => {
  return router.currentRoute.query?.sort
    ? getSortOptions(router.currentRoute.query.sort as sortType)
    : sortOptions ?? {};
};
