

























import { Component, Vue } from 'vue-property-decorator';
import CoverageFirstIcon from '@/assets/icons/policy_coverage_1.svg';
import CoverageSecondIcon from '@/assets/icons/policy_coverage_2.svg';
import CoverageThirdIcon from '@/assets/icons/policy_coverage_3.svg';

@Component({
  components: {
    CoverageFirstIcon,
    CoverageSecondIcon,
    CoverageThirdIcon,
  }
})
export default class PolicyProtectionBlock extends Vue {}
