import { render, staticRenderFns } from "./Policy.history.tab.vue?vue&type=template&id=506c54b8&scoped=true&lang=pug&"
import script from "./Policy.history.tab.vue?vue&type=script&lang=ts&"
export * from "./Policy.history.tab.vue?vue&type=script&lang=ts&"
import style0 from "./Policy.history.tab.vue?vue&type=style&index=0&id=506c54b8&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "506c54b8",
  null
  
)

export default component.exports